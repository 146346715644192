import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import axios from 'axios';
import { Button } from '@mui/material';
import { AiFillDelete } from 'react-icons/ai';



function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const [roleList, setRoleList] = React.useState([]);

    

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.name}
                </TableCell>
                <TableCell align="right">{row.total_coin}</TableCell>
                <TableCell align="right">{row.descriptino}</TableCell>
                <TableCell align="right">{row.offer}</TableCell>
                <TableCell align="right">
                    <IconButton color='error'>
                        <AiFillDelete />
                    </IconButton> 
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                History
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Percentage</TableCell>
                                        <TableCell>Coin</TableCell>
                                        <TableCell align="right">Holding period (days)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.tabledata.map((historyRow) => (
                                        <TableRow key={historyRow.date}>
                                            <TableCell component="th" scope="row">
                                                {historyRow.perc}
                                            </TableCell>
                                            <TableCell>{historyRow.coin}</TableCell>
                                            <TableCell align="right">{historyRow.date_}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function RoleLIst(props) {
    const [dataList, setDataList] = React.useState([]);

    const fetchUsers = async () => {
        const listUser = await axios.get(`${process.env.REACT_APP_API_URL}/list-role`)
        setDataList(listUser.data.result)
        console.log(listUser.data.result);
    }
    React.useEffect(() => {
        fetchUsers();
    }, [])
    React.useEffect(()=>{
        fetchUsers();
    },[props])
    return (
        <TableContainer component={Paper}>
            <p></p>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>Role name </TableCell>
                        <TableCell align="right">Total coin</TableCell>
                        <TableCell align="right">Description</TableCell>
                        <TableCell align="right">Offet</TableCell>
                        <TableCell align="right">Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataList.length > 0 &&
                        <>
                            {dataList.map((list,i)=>(
                                <Row key={i} row={list} ></Row>
                            ))}
                        </>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}

//export default RoleLIst