import React, { useState } from 'react'
import dayjs, { Dayjs } from 'dayjs';
import {
    Card, CardHeader, Grid2, TextField, Breadcrumbs, Typography, FormControl, Button, Divider,
    RadioGroup, FormLabel, FormControlLabel, Radio, InputLabel, Input, InputAdornment,
    IconButton,
    Backdrop,
    CircularProgress
} from '@mui/material'
import { Link } from 'react-router-dom'
import { AiFillDelete, AiFillPlusSquare } from 'react-icons/ai'
import CountrySelect from './CountrySelect'
import MuiPhoneNumber from 'material-ui-phone-number'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import axios from 'axios';


function Individual() {
    const [newIndivual, setNewIndividual] = useState({});
    const [open, setOpen] = React.useState(false);

    const handalAddOppucation = () => {
        if (newOpucation.name && newOpucation.occuption && newOpucation.strt_dat && newOpucation.end_dat) {
            console.log(newOpucation)
            setopucationList(prevArray => [...prevArray, newOpucation]);
            setOpucation({ occuption: '', name: '', strt_dat: null, end_dat: null })
        }
    }

    const [apucationList, setopucationList] = useState([]);
    const [newOpucation, setOpucation] = useState({});

    const handalSubmit = async () => {
        setOpen(true)
        const addIndivdual = await axios.post(`${process.env.REACT_APP_API_URL}/individual/add`, newIndivual)
        setOpen(false)
        setTimeout(() => {
            window.location.reload();
        }, 500);

    }
    const jsDate = (adate, y) => {
        var date = new Date(adate);
        var day = String(date.getDate()).padStart(2, '0');
        var month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        var year = date.getFullYear();
        const formattedDate = `${day}-${month}-${year}`;
        if (y) {
            return year;
        } else {
            return formattedDate;
        }

    }
    const [newEdu, setNewedu] = useState({});
    const [educationList, setEduList] = useState([]);
    const handalSaveEdu = () => {
        if (newEdu.degre && newEdu.instut && newEdu.year) {
            console.log(newEdu)
            setEduList((prev) => [...prev, newEdu])
            setNewedu({ instut: '', degre: '', year: null })
        }
    }

    const handalDeleEdu = (list) => {
        const olderThan3 = educationList.filter(lists => lists.id != list.id);
        setEduList(olderThan3)
    }
    const handalDelOp = (list) => {
        const olderThan30 = apucationList.filter(lists => lists.id != list.id);
        setopucationList(olderThan30)
    }

    return (
        <div>
            <div className='box'>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Dashboard
                    </Link>
                    <Typography sx={{ color: 'text.primary' }}>List</Typography>
                </Breadcrumbs>
            </div>
            <div className='text-inner'>
                <Card style={{ padding: '10px' }}>
                    <h4>Individual</h4>
                    <form>
                        <h6>Personal Data</h6>
                        <hr />
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Family Name" size='small'
                                variant="outlined" value={newIndivual.lname}
                                onChange={(e) => { setNewIndividual((prev) => ({ ...prev, lname: e.target.value })) }}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Given Name" size='small'
                                variant="outlined" value={newIndivual.fname}
                                onChange={(e) => { setNewIndividual((prev) => ({ ...prev, fname: e.target.value })) }}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Middle Name" size='small'
                                variant="outlined" value={newIndivual.mname}
                                onChange={(e) => { setNewIndividual((prev) => ({ ...prev, mname: e.target.value })) }}
                            />
                        </FormControl>
                        <FormControl component="fieldset">
                            <RadioGroup row aria-label="gender" name="row-radio-buttons-group"
                                onChange={(e) => { setNewIndividual((prev) => ({ ...prev, gender: e.target.value })) }}>
                                <FormControlLabel value="female" control={<Radio />} label="Female" />
                                <FormControlLabel value="male" control={<Radio />} label="Male" />
                            </RadioGroup>
                        </FormControl>
                        {/* Residence Address */}
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Residence Address" size='small'
                                value={newIndivual.residantal_address}
                                onChange={(e) => { setNewIndividual((prev) => ({ ...prev, residantal_address: e.target.value })) }}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <CountrySelect />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="State of Residence" size='small' value={newIndivual.state}
                                onChange={(e) => { setNewIndividual((prev) => ({ ...prev, state: e.target.value })) }}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Business Address" size='small' value={newIndivual.b_address}
                                onChange={(e) => { setNewIndividual((prev) => ({ ...prev, b_address: e.target.value })) }}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <CountrySelect />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <MuiPhoneNumber defaultCountry={'in'} label="Phomne number"
                                variant="outlined" size='small' />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <MuiPhoneNumber defaultCountry={'in'}
                                variant="outlined" size='small' label="Business Ph:" />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Where are you registered to vote?" size='small'
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="driver's license state" size='small'
                                value={newIndivual.licence_state}
                                onChange={(e) => { setNewIndividual((prev) => ({ ...prev, licence_state: e.target.value })) }}
                            />
                        </FormControl>

                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Other Residences or Contacts" size='small'
                                value={newIndivual.other_contact}
                                onChange={(e) => { setNewIndividual((prev) => ({ ...prev, other_contact: e.target.value })) }}
                            />
                        </FormControl>

                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <LocalizationProvider dateAdapter={AdapterDayjs} size='small'>
                                <DatePicker size='small'
                                    label="Date of birth"
                                />
                            </LocalizationProvider>
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <LocalizationProvider dateAdapter={AdapterDayjs} size='small'>
                                <DatePicker size='small'
                                    label="Govt ID Expiry date"
                                />
                            </LocalizationProvider>
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Citizenship" size='small'
                                value={newIndivual.citizship}
                                onChange={(e) => { setNewIndividual((prev) => ({ ...prev, citizship: e.target.value })) }}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Social Security or Taxpayer I.D. No." size='small'
                                value={newIndivual.social_sec}
                                onChange={(e) => { setNewIndividual((prev) => ({ ...prev, social_sec: e.target.value })) }}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="price of Gold to be booked in USD" size='small'
                                value={newIndivual.price_gold_booked}
                                onChange={(e) => { setNewIndividual((prev) => ({ ...prev, price_gold_booked: e.target.value })) }}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Address Proof" size='small'
                                type='file' InputLabelProps={{ shrink: true, required: true }}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Id Proof" size='small'
                                type='file' InputLabelProps={{ shrink: true, required: true }}
                            />
                        </FormControl>
                        <hr />
                        <h6>Employment and Business Experience</h6>

                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Present Occupation" size='small'
                                value={newIndivual.present_occuption}
                                onChange={(e) => { setNewIndividual((prev) => ({ ...prev, present_occuption: e.target.value })) }}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Salary" size='small' type='number'
                                value={newIndivual.salary}
                                onChange={(e) => { setNewIndividual((prev) => ({ ...prev, salary: e.target.value })) }}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Your own business or are you  employed" size='small'
                                value={newIndivual.business_employee}
                                onChange={(e) => { setNewIndividual((prev) => ({ ...prev, business_employee: e.target.value })) }}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Business type" size='small'
                                value={newIndivual.business_type}
                                onChange={(e) => { setNewIndividual((prev) => ({ ...prev, business_type: e.target.value })) }}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Description of responsibilities" size='small'
                                value={newIndivual.descr_respons}
                                onChange={(e) => { setNewIndividual((prev) => ({ ...prev, descr_respons: e.target.value })) }}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Present title or position" size='small'
                                value={newIndivual.present_title}
                                onChange={(e) => { setNewIndividual((prev) => ({ ...prev, present_title: e.target.value })) }}
                            />
                        </FormControl>
                        <hr />
                        <p>Prior occupations, employment, and length of service during the past five (5) years</p>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>Occupation</th>
                                    <th>Name of Organization </th>
                                    <th>Years of Service</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr>
                                    <td>
                                        <TextField
                                            label="Occupation" size='small'
                                            variant="outlined" value={newOpucation.occuption}
                                            onChange={(e) => { setOpucation((prev) => ({ ...prev, occuption: e.target.value })) }}
                                        />
                                    </td>
                                    <td>
                                        <TextField
                                            label="Name" size='small'
                                            variant="outlined" value={newOpucation.name}
                                            onChange={(e) => { setOpucation((prev) => ({ ...prev, name: e.target.value })) }}
                                        />
                                    </td>
                                    <td>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DatePicker', 'DatePicker']}>
                                                <DatePicker label="Start date"

                                                    onChange={(newValue) => {
                                                        setOpucation((prev) => ({ ...prev, strt_dat: jsDate(newValue) }))
                                                    }}
                                                />
                                                <DatePicker
                                                    label="End date"
                                                    onChange={(newValue) => {
                                                        setOpucation((prev) => ({ ...prev, end_dat: jsDate(newValue), id: Date.now() }))
                                                    }}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </td>
                                    <td>
                                        <IconButton color='success' onClick={handalAddOppucation}>
                                            <AiFillPlusSquare />
                                        </IconButton>
                                    </td>
                                </tr>
                                {apucationList.length > 0 &&
                                    <>
                                        {apucationList.map((list, i) => (
                                            <tr key={i}>
                                                <td>{list.occuption} </td>
                                                <td>{list.name} </td>
                                                <td>{list.strt_dat}- {list.end_dat} </td>
                                                <td>
                                                    <IconButton color='error' onClick={() => { handalDelOp(list) }}>
                                                        <AiFillDelete />
                                                    </IconButton>
                                                </td>
                                            </tr>
                                        ))}
                                    </>
                                }

                            </tbody>
                        </table>
                        <hr />
                        <h6>Education (college and postgraduate) </h6>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>Institution Attended</th>
                                    <th>Degree </th>
                                    <th>Dates of Attendance</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <TextField
                                            label="Institution" size='small'
                                            variant="outlined" value={newEdu.instut}
                                            onChange={(e) => { setNewedu((prev) => ({ ...prev, instut: e.target.value })) }}
                                        />
                                    </td>
                                    <td>
                                        <TextField
                                            label="Degree" size='small'
                                            variant="outlined" value={newEdu.degre}
                                            onChange={(e) => { setNewedu((prev) => ({ ...prev, degre: e.target.value })) }}
                                        />
                                    </td>
                                    <td>
                                        {/* console.log(jsDate(value,'y')) */}
                                        <LocalizationProvider dateAdapter={AdapterDayjs} size='small'>
                                            <DatePicker size='small'
                                                views={["year"]}
                                                label="Year"
                                                onChange={(value) => { setNewedu((prev) => ({ ...prev, year: jsDate(value, 'y'), id: Date.now() })) }}
                                            />
                                        </LocalizationProvider>
                                    </td>
                                    <td>
                                        <IconButton color='success' onClick={handalSaveEdu}>
                                            <AiFillPlusSquare />
                                        </IconButton>
                                    </td>
                                </tr>
                                {educationList.length > 0 &&
                                    <>
                                        {educationList.map((list, i) => (
                                            <tr key={i}>
                                                <td>{list.instut} </td>
                                                <td>{list.degre} </td>
                                                <td>{list.year} </td>
                                                <td>
                                                    <IconButton color='error' onClick={()=>{ handalDeleEdu(list) }}>
                                                        <AiFillDelete />
                                                    </IconButton>
                                                </td>
                                            </tr>
                                        ))}
                                    </>
                                }
                            </tbody>
                        </table>
                        <hr />
                        <h6>Current Investment Objectives</h6>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Current income" size='small' type='number'
                                value={newIndivual.current_income}
                                onChange={(e) => { setNewIndividual((prev) => ({ ...prev, current_income: e.target.value })) }}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Appreciation" size='small'
                                value={newIndivual.apprecition}
                                onChange={(e) => { setNewIndividual((prev) => ({ ...prev, apprecition: e.target.value })) }}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Tax Shelter" size='small'
                                value={newIndivual.tax_shelter}
                                onChange={(e) => { setNewIndividual((prev) => ({ ...prev, tax_shelter: e.target.value })) }}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Other" size='small'
                                value={newIndivual.other}
                                onChange={(e) => { setNewIndividual((prev) => ({ ...prev, other: e.target.value })) }}
                            />
                        </FormControl>
                        <br />
                        <br />

                        <Button variant="contained" style={{ width: '100%' }}
                            onClick={handalSubmit}>Submit</Button>
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={open}>
                            <CircularProgress color="inherit" />
                        </Backdrop>

                    </form>
                </Card>
            </div>
        </div>
    )
}

export default Individual