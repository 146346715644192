import React from 'react'
import {
    Card, CardHeader, Grid2, TextField, Breadcrumbs, Typography, FormControl, Button, Divider,
    RadioGroup, FormLabel, FormControlLabel, Radio, InputLabel, Input, InputAdornment,
    IconButton
} from '@mui/material'
import { Link } from 'react-router-dom'
import { AiFillPlusSquare } from 'react-icons/ai'

function Business() {
    return (
        <div>
            <div className='box'>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Dashboard
                    </Link>
                    <Typography sx={{ color: 'text.primary' }}>List</Typography>
                </Breadcrumbs>
            </div>
            <div className='text-inner'>
                <Card style={{ padding: '10px' }}>
                    <h4>Business</h4>
                    <form>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Name of Entity" size='small'
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Address of Principal Office" size='small'
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Type of Organization" size='small'
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="State of Organization" size='small'
                            />
                        </FormControl>
                        <hr />
                        <h6>Business</h6>

                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Major Segments of Operation" size='small'
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Length of operation" size='small'
                            />
                        </FormControl>
                        <p>Is the entity a reporting entity under the U.S. Securities Exchange Act of 1934, as amended?</p>
                        <FormControl component="fieldset">
                            <RadioGroup row aria-label="gender" name="row-radio-buttons-group">
                                <FormControlLabel value="female" control={<Radio />} label="Yes" />
                                <FormControlLabel value="male" control={<Radio />} label="No" />
                            </RadioGroup>
                        </FormControl>
                        <hr />
                        <h6>Current Investment Objectives</h6>

                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Current income" size='small'
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Appreciation" size='small'
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Tax Shelter" size='small'
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Other" size='small'
                            />
                        </FormControl>
                        <br />
                        <br />

                        <Button variant="contained" style={{width:'100%'}}>Submit</Button>

                    </form>
                </Card>
            </div>
        </div>
    )
}

export default Business