import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Grid2, IconButton, InputAdornment, TextField, FormControl, InputLabel, Box, OutlinedInput, Button, Backdrop, CircularProgress } from '@mui/material'
import axios from 'axios';
import React, { useState } from 'react'
import { TbBrandGoogleFilled } from 'react-icons/tb';
import { Link, useNavigate } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";


function Login() {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleMouseUpPassword = (event) => {
        event.preventDefault();
    };
    const [user, setUser] = useState({ userid: '', password: '' });
    function onChange(value) {
        console.log("Captcha value:", value);
    }
    const gologin = async () => {
        if (user.userid && user.password) {
            setOpen(true);
            const userData = await axios.post(`${process.env.REACT_APP_API_URL}/user/login`, user);
            if (userData.data.error === 0 && userData.data?.result?.id) {
                localStorage.setItem('user', JSON.stringify(userData.data.result))
                setTimeout(() => {
                    navigate('/dashboard')
                }, 500);
            }else{
                setOpen(false);
                alert('Please enter valid user id or password')
            }
        }else{
            alert('Please enter user id or password')
        }

        //console.log(userData.data.error);
        //navigate('/dashboard')
    }

    return (
        <div>
            <Grid2 container >
                <Grid2 size={6}>
                    <div className='log-box'>
                        <div className='center-div'>
                            <h4 className='text-center'>Login</h4>
                            <p className='text-center'>Enter your email below to login</p>

                            <Box className="box">
                                <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                                    <TextField type='email' label="Email" variant="outlined" size='small'
                                        value={user.userid} onChange={(e) => { setUser((prev) => ({ ...prev, userid: e.target.value })) }} />
                                </FormControl>
                                <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={showPassword ? 'text' : 'password'}
                                        size='small'
                                        value={user.password} onChange={(e) => { setUser((prev) => ({ ...prev, password: e.target.value })) }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    onMouseUp={handleMouseUpPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Password"
                                    />
                                </FormControl>
                                <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                                    <ReCAPTCHA
                                        sitekey="6LevsUUqAAAAALvisRg3xhlTL5bPcVysO6AIXHeu"
                                        onChange={onChange}
                                    />
                                </FormControl>

                                <p className='text-right'>
                                    <Link to="/">Forgot password ?</Link>
                                </p>
                                <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                                    <Button variant="contained"
                                        onClick={gologin}>Login</Button>
                                </FormControl>


                            </Box>
                            <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={open}>
                                <CircularProgress color="inherit" />
                            </Backdrop>
                        </div>
                    </div>
                </Grid2>
                <Grid2 size={6}>
                    <div className='login-bg'>

                    </div>
                </Grid2>
            </Grid2>
        </div>
    )
}

export default Login