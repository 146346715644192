import { Card, CardHeader, Grid2, TextField, Breadcrumbs, Typography, FormControl, Button } from '@mui/material'
import { Link } from 'react-router-dom'
import React, { useState } from 'react'
import RoleTable from './RoleTable'
import axios from 'axios'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import RoleLIst from './RoleLIst'


function Rolemaster() {
    const [rolePersonal, setPersolnal] = useState({});
    const [roleData, setRoleData] = useState([]);

    const [submitData, setsubmitData] = useState(false);

    const [open, setOpen] = React.useState(false);

    const gettblData = (e) => {
        setRoleData(e);
        setPersolnal((prev) => ({ ...prev, tabledata: e }))
    }
    const handalSubmit = () => {
        if(!rolePersonal.name) return alert('enter role name');
        if(rolePersonal.tabledata.length ===0) return alert('enter conin details');

        setOpen(!open);
        console.log(rolePersonal)
        axios.post(`${process.env.REACT_APP_API_URL}/add-role`, rolePersonal)
            .then((res) => {
                setOpen(false);
                setPersolnal({name:'',descriptino:'',offer:'',total_coin:'',tabledata:[]});
                setRoleData([]);
                setsubmitData(true);
            })
    }

    return (
        <div>
            <div className='box'>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Dashboard
                    </Link>
                    <Typography sx={{ color: 'text.primary' }}>List</Typography>
                </Breadcrumbs>
            </div>
            <div className='text-inner'>
                <Card style={{ padding: '10px' }}>
                    <h4>Add role</h4>
                    <div className="row">
                        <div className='col-sm-6' >
                            <form>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField
                                        label="Role name" size='small'
                                        variant="outlined" value={rolePersonal.name}
                                        onChange={(e) => { setPersolnal((prev) => ({ ...prev, name: e.target.value })) }}
                                    />
                                </FormControl>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField
                                        label="Role description" size='small'
                                        variant="outlined" value={rolePersonal.descriptino}
                                        onChange={(e) => { setPersolnal((prev) => ({ ...prev, descriptino: e.target.value })) }}
                                    />
                                </FormControl >
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField
                                        label="Total no of coins" size='small' type='number'
                                        variant="outlined" value={rolePersonal.total_coin}
                                        onChange={(e) => { setPersolnal((prev) => ({ ...prev, total_coin: e.target.value })) }}
                                    />
                                </FormControl >
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField
                                        label="Offer details" size='small'
                                        variant="outlined" value={rolePersonal.offer}
                                        onChange={(e) => { setPersolnal((prev) => ({ ...prev, offer: e.target.value })) }}
                                    />
                                </FormControl >

                            </form>
                        </div>
                        <div className='col-sm-6'>
                            <RoleTable tblData={gettblData} submitFlaf={submitData}  />
                        </div>
                        <div className='col-sm-12 text-center' sx={{ m: 2, }} >
                            <br />
                            <br />
                            <Button variant="contained" onClick={handalSubmit}>Add Role</Button>
                        </div>
                    </div>
                </Card>
            </div>
            <div className='text-inner'>
                <Card style={{ padding: '10px' }}>
                    <h4 className='text-center'>Role list</h4>
                    <RoleLIst hitsubmit={submitData} />
                </Card>
            </div>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
                
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default Rolemaster