import React, { useEffect, useState } from 'react'
import {
    Card, CardHeader, Grid2, TextField, Breadcrumbs, Typography, FormControl, Button, Divider,
    RadioGroup, FormLabel, FormControlLabel, Radio, InputLabel, Input, InputAdornment,
    Autocomplete, OutlinedInput, IconButton,
    Backdrop,
    CircularProgress
} from '@mui/material'
import { Link } from 'react-router-dom'
import BasicTable from './BasicTable'
import { AiOutlineUserAdd } from 'react-icons/ai'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import MuiPhoneNumber from 'material-ui-phone-number';
import axios from 'axios'
import CountrySelect from './CountrySelect'
import { Visibility, VisibilityOff } from '@mui/icons-material'



function SubscriptionMaster() {
    const [newSubscript, setNewSubscript] = useState({});
    const [type, setType] = useState('');
    const [open, setOpen] = React.useState(false);

    const [dataList, setDataList] = React.useState([]);
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleMouseUpPassword = (event) => {
        event.preventDefault();
    };

    const fetchUsers = async () => {
        const listUser = await axios.get(`${process.env.REACT_APP_API_URL}/list-role`)
        const nnData = listUser.data.result;
        var roleList = [];
        for (let i = 0; i < nnData.length; i++) {
            roleList.push({ id: nnData[i].id, label: nnData[i].name })
        }
        setDataList(roleList)
        //setDataList(listUser.data.result)
        console.log(listUser.data.result);
    }
    const handalSubmit = async () => {
        setOpen(true)

        const submitSubscription = await axios.post(`${process.env.REACT_APP_API_URL}/subscription/add`, newSubscript)
        console.log(submitSubscription)
        setNewSubscript({ age: '', company_name: '', contact_person: '', email: '', fname: '', gender: '', lname: '', password: '', symbol: '', country: '', role: '' })
        setTimeout(() => {
            setOpen(false)
        }, 300);

    }

    useEffect(() => {
        fetchUsers();
    }, [])

    return (
        <div>
            <div className='box'>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Dashboard
                    </Link>
                    <Typography sx={{ color: 'text.primary' }}>List</Typography>
                </Breadcrumbs>
            </div>

            <div className='text-inner'>
                <Card style={{ padding: '10px' }}>
                    <h3 className='text-center'>Subscription Master</h3>
                    <Divider />
                    <hr />
                    <div >
                        <form>
                            <h5>Personal Details</h5>
                            <FormControl sx={{ m: 1, }} variant="outlined">
                                <TextField
                                    label="First Name" size='small'
                                    variant="outlined" value={newSubscript.fname}
                                    onChange={(e) => { setNewSubscript((prev) => ({ ...prev, fname: e.target.value })) }}
                                />
                            </FormControl >
                            <FormControl sx={{ m: 1, }} variant="outlined">
                                <TextField
                                    label="Last name" size='small'
                                    variant="outlined" value={newSubscript.lname}
                                    onChange={(e) => { setNewSubscript((prev) => ({ ...prev, lname: e.target.value })) }}
                                />
                            </FormControl >
                            <FormControl sx={{ m: 1, }} variant="outlined">
                                <TextField
                                    label="Company name" size='small'
                                    variant="outlined" value={newSubscript.company_name}
                                    onChange={(e) => { setNewSubscript((prev) => ({ ...prev, company_name: e.target.value })) }}
                                />
                            </FormControl >
                            <FormControl sx={{ m: 1, }} variant="outlined">
                                <CountrySelect />
                            </FormControl >
                            <FormControl sx={{ m: 1, }} variant="outlined">
                                <TextField
                                    label="Symbol " size='small'
                                    variant="outlined" value={newSubscript.symbol}
                                    onChange={(e) => { setNewSubscript((prev) => ({ ...prev, symbol: e.target.value })) }}
                                />
                            </FormControl >
                            <FormControl sx={{ m: 1, }} variant="outlined">
                                <TextField
                                    label="Contact person " size='small'
                                    variant="outlined" value={newSubscript.contact_person}
                                    onChange={(e) => { setNewSubscript((prev) => ({ ...prev, contact_person: e.target.value })) }}
                                />
                            </FormControl >
                            <FormControl sx={{ m: 1, }} variant="outlined">
                                <LocalizationProvider dateAdapter={AdapterDayjs} size='small'>
                                    <DatePicker size='small' />
                                </LocalizationProvider>
                            </FormControl >
                            <FormControl sx={{ m: 1, }} variant="outlined">
                                <TextField
                                    label="Age" size='small' type='number'
                                    variant="outlined" value={newSubscript.age}
                                    onChange={(e) => { setNewSubscript((prev) => ({ ...prev, age: e.target.value })) }}
                                />
                            </FormControl >
                            <FormControl sx={{ m: 1, }} variant="outlined">
                                <MuiPhoneNumber defaultCountry={'in'}
                                    variant="outlined" size='small' />
                            </FormControl >
                            <FormControl component="fieldset">
                                <label>Gender</label>
                                <RadioGroup row aria-label="gender" name="row-radio-buttons-group"
                                    onChange={(e) => { setNewSubscript((prev) => ({ ...prev, gender: e.target.value })) }}>
                                    <FormControlLabel value="female" control={<Radio />} label="Female" />
                                    <FormControlLabel value="male" control={<Radio />} label="Male" />
                                </RadioGroup>
                            </FormControl>
                            <hr />
                            <div className='row'>
                                <div className='col-sm-12'>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Type</FormLabel>
                                        <RadioGroup row aria-label="gender" name="row-radio-buttons-group"
                                            onChange={(e) => { setType(e.target.value) }}>
                                            <FormControlLabel value="ambassador" control={<Radio />} label="Ambassador" />
                                            <FormControlLabel value="investor" control={<Radio />} label="Investor" />
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                                {type && type === 'investor' &&
                                    <div className='col-sm-12'>
                                        <FormControl sx={{ m: 1, }} variant="outlined">
                                            <TextField
                                                label="Investment Amount($)" type='number'
                                                size='small'
                                                onChange={(e) => { setNewSubscript((prev) => ({ ...prev, invstamt: e.target.value })) }}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                }}
                                            />
                                        </FormControl >
                                        <FormControl sx={{ m: 1, }} variant="outlined">
                                            <TextField
                                                label="Price per Coin($)" type='number'
                                                size='small'
                                                onChange={(e) => { setNewSubscript((prev) => ({ ...prev, price_coin: e.target.value })) }}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                }}
                                            />
                                        </FormControl >
                                        <Button variant="contained" size="large">Buy</Button>
                                    </div>}
                                {type && type === 'ambassador' &&
                                    <div className='col-sm-12'>
                                        <FormControl sx={{ m: 1, }} variant="outlined">
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                options={dataList}
                                                onChange={(e, newValue) => { console.log(newValue) }}
                                                sx={{ width: 300 }}
                                                renderInput={(params) => <TextField {...params} label="Role list" />}
                                            />
                                        </FormControl >
                                        <div>
                                            <BasicTable />
                                        </div>
                                    </div>}
                                <div className='col-sm-12'>
                                    <hr />

                                    <FormControl sx={{ m: 1, }} variant="outlined">
                                        <TextField
                                            label="Email" size='small' type='email'
                                            variant="outlined" value={newSubscript.email}
                                            onChange={(e) => { setNewSubscript((prev) => ({ ...prev, email: e.target.value })) }}
                                        />
                                    </FormControl >

                                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            type={showPassword ? 'text' : 'password'}
                                            size='small'
                                            value={newSubscript.password}
                                            onChange={(e) => { setNewSubscript((prev) => ({ ...prev, password: e.target.value })) }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        onMouseUp={handleMouseUpPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label="Password"
                                        />
                                    </FormControl>
                                    <br />
                                    <Button variant="contained" style={{ width: '100%' }}
                                        onClick={handalSubmit}>Create
                                        <AiOutlineUserAdd />
                                    </Button>

                                    <Backdrop
                                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                        open={open}>
                                        <CircularProgress color="inherit" />
                                    </Backdrop>

                                </div>

                            </div>
                        </form>
                    </div>
                </Card>
            </div>
        </div>
    )
}

export default SubscriptionMaster