import React, { useEffect, useState } from 'react';

import { Route, Routes } from "react-router-dom";
import Home from "./home/Home";
import Welcome from "./home/Welcome";
import Investor from "./home/Investor";


import logo from "./logo.png";
import culogo2Logo from './culogo2.png'; // culogo2.png
import ReCAPTCHA from "react-google-recaptcha";
import { FaThreads } from "react-icons/fa6";


import { FaBeer, FaFacebook, FaInstagram, FaLinkedin, FaTwitter, FaYoutube } from "react-icons/fa";
import { Link } from 'react-router-dom';
import Location from "./app/Location";
import Business from "./home/Business";
import CommingSoon from "./home/CommingSoon";

import CookieConsent from "react-cookie-consent";
import Login from './Login';

function FrontPage() {
    const [ goldPrice, setGoldPrice] = useState(0);

    const [seeMore, setSeeMore] = useState(false);
    
    useEffect(()=>{
      fetch('https://redeembank.com/api/goldrate.php',{
        method:'GET',
        headers:{
          accept:'application/json'
        }
      }
      )
      .then((res)=>res.json())
      .then((res)=>{
        if(res.error){
          alert(res.error)
        }else{
          console.log(res.items[0].xauPrice)
          setGoldPrice(res.items[0].xauPrice.toFixed(2))
        }
        console.log(res)
        //setGoldPrice(res.price_gram_22k.toFixed(2))
      })
  
    })
    return (
      <div className="App">
        <CookieConsent>
        <div className='scroll'>
          <p>Our Privacy Policy defines how we collect, use,
            and disclose personal information when you visit, browse or use our website. By accessing or using the Website, you agree to the terms of this Policy. If you do not agree with any part of this Policy,
            you must not use the Website.</p>
          {seeMore == false &&
            <>
              <span className='see-more' onClick={(e) => { setSeeMore(!seeMore) }}>See more..</span>
            </>
          }
          {seeMore == true &&
            <>
              <ul>
                <li>1. Information We Collect and use for Analytics </li>
                <li>1.1 Personal Information:
                  We may collect personal information that you voluntarily provide to us when you
                  interact with the Website. This may include your name, email address, phone number,
                  and any other information you choose to provide.
                </li>
                <li>
                  1.2 Usage Information:
                  We may automatically collect certain information when you visit the Website,
                  such as your IP address, browser type, operating system, referring website, pages viewed,
                  and the dates and times of your visits.
                  This information is collected using cookies, web beacons, and similar technologies.
                </li>
                <li>
                  2. Use of Information
                </li>
                <li>
                  2.1 We may use the information we collect to:
                  <br />
                  – Provide, maintain, and improve the Website’s functionality.
                  <br />
                  – Respond to your inquiries and communicate with you about our services.
                  <br />
                  – Send you promotional materials or newsletters, subject to your consent where required by law.
                  <br />
                  – Personalize and enhance your experience on the Website.
                  <br />
                  – Monitor and analyze usage and trends to improve the Website and our services.
                  <br />
                  – Monitor and analyze usage and trends to improve the Website and our services.
                  <br />
                  – Protect the security and integrity of the Website and our users.
                </li>
                <li>
                  3. Disclosure of Information
                </li>
                <li>
                  3.1 We may share your personal information with:
                  <br />
                  – Service providers who assist us in operating the Website and providing our services.
                  <br />
                  – Third-party analytics providers to analyze usage and trends.
                  <br />
                  – Law enforcement authorities, government officials, or other third parties when required by law or in response to a legal process.
                  <br />
                  – Other third parties with your consent or at your direction.
                </li>
                <li>
                  4. Data Security
                </li>
                <li>
                  4.1 We take reasonable measures to protect the security of your personal information. However, no method of transmission over
                  the internet or electronic storage is completely secure, and we cannot guarantee absolute security.
                </li>
                <li>
                  5. Third-Party Links
                </li>
                <li>
                  5.1 The Website may contain links to third-party websites. We are not responsible for the privacy practices or the content of such websites.
                  We encourage you to read the privacy policies of those websites before providing any personal information.
                </li>
                <li>
                  6. Children’s Privacy
                </li>
                <li>
                  6.1 The Website is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from children. If we become aware that we have inadvertently
                  collected personal information from a child, we will take steps to delete it as soon as possible.
                </li>
                <li>
                  7. Your Rights
                </li>
                <li>
                  7.1 You may have certain rights regarding your personal information, including the right to access, correct, or delete the information we hold about you.
                  To exercise your rights, please contact us using the information provided at the end of this Policy.
                </li>
                <li>
                  8. Changes to the Policy
                </li>
                <li>
                  8.1 We may update this Policy from time to time. Any changes will be effective immediately upon posting on the Website. Your continued use
                  of the Website after any modifications to the Policy constitutes your acceptance of the revised terms.
                </li>
                <li>
                  9. Contact Us
                </li>
                <li>
                  9.1 If you have any questions, concerns, or requests regarding this Policy or the privacy practices of the Website, please contact us at support@cunninghammining.com or support@nuggettrap.com
                  By using the Website, you acknowledge that you have read, understood, and agreed to be bound by this Privacy Policy. If you do not agree to this Policy, please refrain from using the Website.
                </li>
              </ul>
              {seeMore == true &&
                <>
                  <span className='see-more' onClick={(e) => { setSeeMore(!seeMore) }}>See less..</span>
                </>
              }
            </>
          }
        </div>
      </CookieConsent>
        {/* <Routes>
        <Route path="/" element={<Welcome /> }></Route>
        <Route path="/investor" element={<Investor /> }></Route>
        <Route path="/tokenomics" element={<Home /> }></Route>
        </Routes> */}
        <div className='container main' >
          <div className='box'>
          <div className='box-header'>
            <div className="row">
              <div className="col-sm-6 col-xs-6 textalignleft" > <a href="https://cunninghammining.com/" target="_blank">
              <img src={culogo2Logo} alt="" style={{height:'50px'}} /></a>
              </div>
              <div className="col-sm-6 col-xs-6 textalignright">
              <div className='media-box_'>
                <a
                  href='https://www.facebook.com/profile.php?id=61552312260533' target='_bank'>
                  <FaFacebook /></a>
                <a href='https://twitter.com/Nuggettrapgold' target='_bank'>
                  <FaTwitter /></a>
  
                <a href='https://www.instagram.com/nuggettrapgold/' target='_bank'>
                  <FaInstagram /></a>
                <a href='https://www.linkedin.com/company/nugget-trap/' target='_bank'>
                  <FaLinkedin /></a>
                <a href='https://www.threads.net/@nuggettrapgold' target='_bank'>
                  <FaThreads />
                </a>
                <a href='https://www.youtube.com/@GoingforGoldNGT' target='_bank'>
                  <FaYoutube />
                </a>
              </div>
              </div>
            </div>
           
            
              
              <img src={logo} alt='logo' className='logo' />
              <h3 class="center">Welcome to Nugget Trap {' '}
                <span className="gold">  Gold Price <span className="text-red"> {goldPrice} CAD</span>
               </span></h3>
               <div>
                <Link to='/login'>Create token</Link>
               </div>
              
              <div className='ul-menu'>
                <ul>
                  <li><Link to='/'>Home</Link> </li>
                  <li><Link target="_blank" to='https://drive.google.com/file/d/1oGSZOqPsmHZ9Vh2EXSCiu4hX-p3seUEV/view?usp=sharing'>Assay Report</Link> </li>
                  <li><Link to='/home/investor'>Investors</Link> </li>
                  <li><Link to='/home/location'>Location</Link></li>
                  <li><Link to='/home/tokenomics'>Digital Assets Tokenomics</Link> </li>
                  {/* <li><Link className="gold">Price 22KT {goldPrice} CAD/Gram </Link> </li> */}
                </ul>
              </div>
            </div>
            <div className='box-body'>
              <Routes>
                <Route path="/" element={<Welcome />}></Route>
                <Route path="/investor" element={<CommingSoon />}></Route>
                <Route path="/tokenomics" element={<Home />}></Route>
                <Route path="/business" element={<Business /> }></Route>
                <Route path="/business-2" element={<Investor /> }></Route>
                <Route path="/location" element={<Location /> }></Route>
                <Route path="/login" element={<Login /> }></Route>
              </Routes>
            </div>
          </div>
        </div>
      </div>
    );
}

export default FrontPage