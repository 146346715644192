import React, { useState } from 'react'
import {
    Card, CardHeader, Grid2, TextField, Breadcrumbs, Typography, FormControl, Button, Divider,
    RadioGroup, FormLabel, FormControlLabel, Radio, InputLabel, Input, InputAdornment,
    Backdrop,
    CircularProgress
} from '@mui/material'
import { Link } from 'react-router-dom'
import BasicTable from './BasicTable'
import axios from 'axios';

function Invite() {
    const [newInvite, setInvite] = useState({name:'',description:'',email:'',role:''});
    const [open, setOpen] = React.useState(false);
    const handalSubmit = async () => {
        setOpen(true);
        const addInvite = await axios.post(`${process.env.REACT_APP_API_URL}/invite/add`, newInvite)
        setInvite({name:'',description:'',email:'',role:'' })
        setOpen(false);
    }
    return (
        <div>
            <div className='box'>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Dashboard
                    </Link>
                    <Typography sx={{ color: 'text.primary' }}>List</Typography>
                </Breadcrumbs>
            </div>
            <div className='text-inner'>
                <Card style={{ padding: '10px' }}>
                    <h4>Invite link</h4>
                    <form>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Name" size='small'
                                variant="outlined" value={newInvite.name}
                                onChange={(e) => { setInvite((prev) => ({ ...prev, name: e.target.value })) }}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Description" size='small'
                                variant="outlined" value={newInvite.description}
                                onChange={(e) => { setInvite((prev) => ({ ...prev, description: e.target.value })) }}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Email" size='small'
                                variant="outlined" value={newInvite.email}
                                onChange={(e) => { setInvite((prev) => ({ ...prev, email: e.target.value })) }}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Role" size='small'
                                variant="outlined" value={newInvite.role}
                                onChange={(e) => { setInvite((prev) => ({ ...prev, role: e.target.value })) }}
                            />
                        </FormControl>
                        <Button variant="contained" style={{ width: '100%' }}
                        onClick={handalSubmit}>Submit</Button>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <hr />
                                <BasicTable />
                            </div>
                        </div>
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={open}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </form>
                </Card>
            </div>
        </div>
    )
}

export default Invite