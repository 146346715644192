import React from 'react'
import commingsoon from '../isv.png';

function CommingSoon() {
  return (
    <div>
        <img src={commingsoon} alt='' style={{width:'100%'}} />
    </div>
  )
}

export default CommingSoon