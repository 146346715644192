import React from 'react'
import locatioMap from '../property.jpeg';
import Header from './Header';

function Location() {
    return (
        <div>
            <div className='text-center' style={{paddingTop:'30px'}}>
                    <img src={locatioMap} alt='location' className='image-responsive' />
                </div>
        </div>
    )
}

export default Location