
import "bootstrap/dist/css/bootstrap.min.css";

import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/js/src/collapse.js";

import './App.css';
import React, { useEffect, useState } from 'react';

import { Route, Routes } from "react-router-dom";
import Home from "./home/Home";
import Welcome from "./home/Welcome";
import Investor from "./home/Investor";


import logo from "./logo.png";
import culogo2Logo from './culogo2.png'; // culogo2.png
import ReCAPTCHA from "react-google-recaptcha";

import { FaBeer, FaFacebook, FaInstagram, FaLinkedin, FaTwitter, FaYoutube } from "react-icons/fa";
import { Link } from 'react-router-dom';
import Location from "./app/Location";
import Business from "./home/Business";
import CommingSoon from "./home/CommingSoon";
import FrontPage from "./FrontPage";
import Login from "./Login";
import Dashboard from "./admin/Dashboard";

/*
import Home from "./app/Home";
import Location from "./app/Location";
import Reg from "./app/Reg";
import IndvReg from "./app/IndvReg";
import BusinessReg from "./app/BusinessReg";
import DigitalToken from "./app/DigitalToken";
import $ from "jquery"
*/

function App() {
 
  return (
    <Routes>
      <Route path="/" element={ <FrontPage /> }></Route>
      <Route path="/home/*" element={ <FrontPage /> }></Route>
      <Route path="/login" element={<Login />}></Route>
      <Route path="/dashboard/*" element={<Dashboard />}></Route>
    </Routes>
  );
}

export default App;
