import React from 'react'
import { Link } from 'react-router-dom';
import {
    Drawer, AppBar, Toolbar, IconButton, Input, InputAdornment,
    TextField, FormControl, InputLabel, Box, OutlinedInput, Button, List, ListItem, ListItemText, Typography, CssBaseline,
    Breadcrumbs,
    Card,
    CardHeader,
    Paper
} from '@mui/material';

function Home() {
    return (
        <div>
            <div className='box'>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Dashboard
                    </Link>
                    <Typography sx={{ color: 'text.primary' }}>List</Typography>
                </Breadcrumbs>
            </div>
            <div className='text-inner'>
                <Card style={{ padding: '10px' }}>
                    <CardHeader>List</CardHeader>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Percentage</th>
                                <th>Coin</th>
                                <th>Holding period(days)</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>8.33</td>
                                <td>799.8</td>
                                <td>2023-01-01</td>
                                <td>Completed</td>
                            </tr>
                            <tr>
                                <td>8.33</td>
                                <td>799.8</td>
                                <td>2023-01-01</td>
                                <td>Completed</td>
                            </tr>
                            <tr>
                                <td>8.33</td>
                                <td>799.8</td>
                                <td>2023-01-01</td>
                                <td>Completed</td>
                            </tr>
                            <tr>
                                <td>8.33</td>
                                <td>799.8</td>
                                <td>2023-01-01</td>
                                <td>Completed</td>
                            </tr>
                        </tbody>
                    </table>
                </Card>
            </div>
        </div>
    )
}

export default Home