import React, { useState } from 'react'
import logo from "../logo.png";
import propertyImg from '../property.jpeg'
import { Link } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";

import { FaFacebook, FaInstagram, FaTwitter, } from "react-icons/fa";
import DatePicker from "react-datepicker";
import axios from 'axios';

import "react-datepicker/dist/react-datepicker.css";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import culogo2Logo from '../culogo2.png';


function Investor() {
    const [startDate, setStartDate] = useState(new Date());
    const [formData, setFormData] = useState({
        f_name: '',
        l_name: '',
        email: '',
        mobile: '',
        address: '',
        state: '',
        city: '',
        zip: '',
        dob: '',

        anual_income: '',
        invest_amount: '',

    })
    const [fileIndestExp, setFileIndestExp] = useState();
    const [fileTokenOffer, setFileTokenOffer] = useState();
    const [fileIdProof, setFileIdProof] = useState();
    const [fileAddressProof, setFileAddressProof] = useState();
    const [fileExtraProof, setFileExtraProof] = useState();

    const [serverUrl, setServerUrl] = useState('https://nuggettrap.com/api/');
    const onChange = (value) => {
        console.log("Captcha value:", value);
    }

    const handalSubmit = (e) => {
        e.preventDefault();
        if(formData.f_name ===""){
            alert('Please enter Name.!');
            return;
        }
        const formdata = new FormData();
        formdata.append('fileIndestExp', fileIndestExp);
        formdata.append('fileTokenOffer', fileTokenOffer);
        formdata.append('fileIdProof', fileIdProof);
        formdata.append('fileAddressProof', fileAddressProof);
        formdata.append('fileExtraProof', fileExtraProof);
        formdata.append('startDate', startDate);
        formdata.append('formData', JSON.stringify(formData));
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                accept: 'application/json'
            },
        };
        axios.post(serverUrl + "save-investor.php", formdata, config).then((response) => {
            toast('Data saved successfully!', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setTimeout(() => {
                setFormData({
                    f_name: '',
                    l_name: '',
                    email: '',
                    mobile: '',
                    address: '',
                    state: '',
                    city: '',
                    zip: '',
                    dob: '',
                    anual_income: '',
                    invest_amount: '',
                })
            }, 100);
        });
    }
    return (
        <div >
            <form method='post' >
                <p><Link to='/business' >Business</Link></p>
                <div className='row'>
                    <div className="mb-3 col-md-4">
                        <label className="form-label">First name
                            <span className='text-danger'>*</span>
                        </label>
                        <input type="text" className="form-control"
                            value={formData.f_name}
                            onChange={(e) => { setFormData((prev) => ({ ...prev, f_name: e.target.value })) }} />
                    </div>
                    <div className="mb-3 col-md-4">
                        <label className="form-label">Last name
                            <span className='text-danger'>*</span>
                        </label>
                        <input type="text" className="form-control" value={formData.l_name}
                            onChange={(e) => { setFormData((prev) => ({ ...prev, l_name: e.target.value })) }} />
                    </div>
                    <div className="mb-3 col-md-4">
                        <label className="form-label">Email
                            <span className='text-danger'>*</span>
                        </label>
                        <input type="text" className="form-control" value={formData.email}
                            onChange={(e) => { setFormData((prev) => ({ ...prev, email: e.target.value })) }} />
                    </div>
                </div>
                <div className='row'>
                    <div className="mb-3 col-md-4">
                        <label className="form-label">Mobile <span className='text-danger'>*</span></label>
                        <input type="text" className="form-control" value={formData.mobile}
                            onChange={(e) => { setFormData((prev) => ({ ...prev, mobile: e.target.value })) }} />
                    </div>
                    <div className="mb-3 col-md-4">
                        <label className="form-label">Address <span className='text-danger'>*</span></label>
                        <input type="text" className="form-control" value={formData.address}
                            onChange={(e) => { setFormData((prev) => ({ ...prev, address: e.target.value })) }} />
                    </div>
                    <div className="mb-3 col-md-4">
                        <label className="form-label">State <span className='text-danger'>*</span></label>
                        <input type="text" className="form-control" value={formData.state}
                            onChange={(e) => { setFormData((prev) => ({ ...prev, state: e.target.value })) }} />
                    </div>
                </div>
                <div className='row'>
                    <div className="mb-3 col-md-4">
                        <label className="form-label">City <span className='text-danger'>*</span></label>
                        <input type="text" className="form-control" value={formData.city}
                            onChange={(e) => { setFormData((prev) => ({ ...prev, city: e.target.value })) }} />
                    </div>
                    <div className="mb-3 col-md-4">
                        <label className="form-label">Zip <span className='text-danger'>*</span> </label>
                        <input type="text" className="form-control" value={formData.zip}
                            onChange={(e) => { setFormData((prev) => ({ ...prev, zip: e.target.value })) }} />
                    </div>
                    <div className="mb-3 col-md-4">
                        <label className="form-label">Date of Birth <span className='text-danger'>*</span></label><br />
                        <DatePicker
                            dateFormat="yyyy-MM-dd"
                            selected={startDate}
                            className="form-control"
                            onChange={(date) => setStartDate(date)}
                        />
                    </div>
                </div>
                <h4>Financial Information</h4>
                <hr />
                <div className='row'>
                    <div className="mb-3 col-md-4">
                        <label className="form-label">Annual Income <span className='text-danger'>*</span></label>
                        <input type="text" className="form-control" value={formData.anual_income}
                            onChange={(e) => { setFormData((prev) => ({ ...prev, anual_income: e.target.value })) }} />
                    </div>
                    <div className="mb-3 col-md-4">
                        <label className="form-label">Investment Amount Intended</label>
                        <input type="text" className="form-control" value={formData.invest_amount}
                            onChange={(e) => { setFormData((prev) => ({ ...prev, invest_amount: e.target.value })) }} />
                    </div>
                </div>
                <h4>Investment Experience</h4>
                <hr />
                <div className='row'>
                    <div className="mb-3 col-md-4">
                        <label className="form-label">Cryptocurrency Investment Experience</label>
                        <input type="file" onChange={(e) => { setFileIndestExp(e.target.files[0]) }} />
                    </div>
                    <div className="mb-3 col-md-4">
                        <label className="form-label">Experience with Token Offerings</label>
                        <input type="file" onChange={(e) => { setFileTokenOffer(e.target.files[0]) }} />
                    </div>
                    <div className="mb-3 col-md-4">
                        <label className="form-label">Proof of Identity <span className='text-danger'>*</span></label>
                        <input type="file" onChange={(e) => { setFileIdProof(e.target.files[0]) }} />
                    </div>
                </div>
                <div className='row'>
                    <div className="mb-3 col-md-4">
                        <label className="form-label">Proof of Address <span className='text-danger'>*</span></label>
                        <input type="file" onChange={(e) => { setFileAddressProof(e.target.files[0]) }} />
                    </div>
                    <div className="mb-3 col-md-4">
                        <label className="form-label">Any Additional Supporting Financial Information:</label>
                        <input type="file" onChange={(e) => { setFileExtraProof(e.target.files[0]) }} />
                    </div>
                </div>
                <div className="mb-3">
                    <p className='disclamr'>
                        Declaration: <br />
                        I, hereby declare that the information provided above is accurate and complete to
                        the best of my knowledge. I understand the risks associated with cryptocurrency investments and
                        confirm my intention to invest in Cunningham Mining.
                    </p>
                    <hr />
                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <div>
                            <ReCAPTCHA
                                sitekey="6LcHwQUpAAAAAG_RZeMJs-oON-mGHGp_2bplJN-O"
                                onChange={onChange}
                                theme="dark"
                            />
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <button type="button" className="btn btn-primary btn-block btn-lg"
                            onClick={handalSubmit}>Submit</button>
                    </div>
                </div>
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored" />
            </form>
        </div>
    )
}

export default Investor