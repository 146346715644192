import React, { useState } from 'react'
import DatePicker from "react-datepicker";
import axios from 'axios';

import "react-datepicker/dist/react-datepicker.css";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import culogo2Logo from '../culogo2.png';
import ReCAPTCHA from "react-google-recaptcha";

function Business() {
    const [startDate, setStartDate] = useState(new Date());
    const [formData, setFormData] = useState({
        f_name: '',
        l_name: '',
        email: '',
        mobile: '',
        address: '',
        state: '',
        city: '',
        zip: '',
        business_name: '',
        reg_number: '',
        business_type: '',
        country_reg: '',
        anual_income: '',
        nit_profit: '',
        invest_amt: '',
        source_invest: ''
    })
    //const [fileIndestExp, setFileIndestExp] = useState();
    const [fileCryptoCurrency, setCryptoCurrency] = useState();
    const [fileTokenOffers, setTokenOffers] = useState();
    const [fileVefification, setVefification] = useState();
    const [fileAddressProof, setAddressProof] = useState();
    const [fileBusinessReg, setBusinessReg] = useState();
    const [fileAddressBusiness, setAddressBusiness] = useState();
    const [fileFileAddressProof, setFileAddressProof] = useState();
    const [FileExtraProof, setFileExtraProof] = useState();

    const [serverUrl, setServerUrl] = useState('https://nuggettrap.com/api/');
    const onChange = (value) => {
        console.log("Captcha value:", value);
    }

    const handalSubmit = (e) => {
        e.preventDefault();
        if(formData.f_name ===""){
            alert('Please enter Name.!');
            return;
        }
        const formdata = new FormData();
        formdata.append('fileCryptoCurrency', fileCryptoCurrency);
        formdata.append('fileTokenOffers', fileTokenOffers);
        formdata.append('fileVefification', fileVefification);
        formdata.append('fileAddressProof', fileAddressProof);
        formdata.append('fileBusinessReg', fileBusinessReg);
        formdata.append('fileAddressBusiness', fileAddressBusiness);
        formdata.append('fileFileAddressProof', fileFileAddressProof);
        formdata.append('FileExtraProof', FileExtraProof);
       console.log(formData)
        formdata.append('formData', JSON.stringify(formData));
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                accept: 'application/json'
            },
        };
        axios.post(serverUrl + "save-business-investor.php", formdata, config).then((response) => {
            toast('Data saved successfully!', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setTimeout(() => {
                
            }, 100);
        });
    }

  return (
    <div>
        <div>
                    <form method='post' >
                        <div className='row'>
                            <div className="mb-3 col-md-4">
                                <label className="form-label">First name
                                    <span className='text-danger'>*</span>
                                </label>
                                <input type="text" className="form-control"
                                    value={formData.f_name}
                                    onChange={(e) => { setFormData((prev) => ({ ...prev, f_name: e.target.value })) }} />
                            </div>
                            <div className="mb-3 col-md-4">
                                <label className="form-label">Last name
                                    <span className='text-danger'>*</span>
                                </label>
                                <input type="text" className="form-control" value={formData.l_name}
                                    onChange={(e) => { setFormData((prev) => ({ ...prev, l_name: e.target.value })) }} />
                            </div>
                            <div className="mb-3 col-md-4">
                                <label className="form-label">Email
                                    <span className='text-danger'>*</span>
                                </label>
                                <input type="text" className="form-control" value={formData.email}
                                    onChange={(e) => { setFormData((prev) => ({ ...prev, email: e.target.value })) }} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className="mb-3 col-md-4">
                                <label className="form-label">Mobile <span className='text-danger'>*</span></label>
                                <input type="text" className="form-control" value={formData.mobile}
                                    onChange={(e) => { setFormData((prev) => ({ ...prev, mobile: e.target.value })) }} />
                            </div>
                            <div className="mb-3 col-md-4">
                                <label className="form-label">Address <span className='text-danger'>*</span></label>
                                <input type="text" className="form-control" value={formData.address}
                                    onChange={(e) => { setFormData((prev) => ({ ...prev, address: e.target.value })) }} />
                            </div>
                            <div className="mb-3 col-md-4">
                                <label className="form-label">State <span className='text-danger'>*</span></label>
                                <input type="text" className="form-control" value={formData.state}
                                    onChange={(e) => { setFormData((prev) => ({ ...prev, state: e.target.value })) }} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className="mb-3 col-md-4">
                                <label className="form-label">City <span className='text-danger'>*</span></label>
                                <input type="text" className="form-control" value={formData.city}
                                    onChange={(e) => { setFormData((prev) => ({ ...prev, city: e.target.value })) }} />
                            </div>
                            <div className="mb-3 col-md-4">
                                <label className="form-label">Zip <span className='text-danger'>*</span> </label>
                                <input type="text" className="form-control" value={formData.zip}
                                    onChange={(e) => { setFormData((prev) => ({ ...prev, zip: e.target.value })) }} />
                            </div>
                            <div className="mb-3 col-md-4">

                            </div>
                        </div>
                        <h4 className=''>Business Information</h4>
                        <hr />
                        <div className='row'>
                            <div className="mb-3 col-md-4">
                                <label className="form-label">Legal Business Name <span className='text-danger'>*</span></label>
                                <input type="text" className="form-control" value={formData.business_name}
                                    onChange={(e) => { setFormData((prev) => ({ ...prev, business_name: e.target.value })) }} />
                            </div>
                            <div className="mb-3 col-md-4">
                                <label className="form-label">Business Registration Number</label>
                                <input type="text" className="form-control" value={formData.reg_number}
                                    onChange={(e) => { setFormData((prev) => ({ ...prev, reg_number: e.target.value })) }} />
                            </div>
                            <div className="mb-3 col-md-4">
                                <label className="form-label">Type of Business (e.g., Corporation, LLC)</label>
                                <input type="text" className="form-control" value={formData.business_type}
                                onChange={(e) => { setFormData((prev) => ({ ...prev, business_type: e.target.value })) }}
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className="mb-3 col-md-4">
                                <label className="form-label">Country of Registration:</label>
                                <input type="text" className="form-control" value={formData.country_reg}
                                onChange={(e) => { setFormData((prev) => ({ ...prev, country_reg: e.target.value })) }}
                                />
                            </div>
                        </div>

                        <h4 className=''>Financial Information</h4>
                        <hr />
                        <div className='row'>
                            <div className="mb-3 col-md-4">
                                <label className="form-label">Annual Income <span className='text-danger'>*</span></label>
                                <input type="text" className="form-control" value={formData.anual_income}
                                    onChange={(e) => { setFormData((prev) => ({ ...prev, anual_income: e.target.value })) }} />
                            </div>
                            <div className="mb-3 col-md-4">
                                <label className="form-label">Net Profit</label>
                                <input type="text" className="form-control" value={formData.nit_profit}
                                    onChange={(e) => { setFormData((prev) => ({ ...prev, nit_profit: e.target.value })) }} />
                            </div>
                            <div className="mb-3 col-md-4">
                                <label className="form-label">Intended Investment Amount</label>
                                <input type="text" className="form-control"
                                value={formData.invest_amt}
                                onChange={(e) => { setFormData((prev) => ({ ...prev, invest_amt: e.target.value })) }}
                                 />
                            </div>
                            <div className="mb-3 col-md-4">
                                <label className="form-label">Source of Funds for Investment</label>
                                <input type="text" className="form-control"
                                value={formData.source_invest}
                                onChange={(e) => { setFormData((prev) => ({ ...prev, source_invest: e.target.value })) }}
                                 />
                            </div>
                        </div>

                        <h4 className=''>Business Experience</h4>
                        <hr />
                        <div className='row'>
                            <div className="mb-3 col-md-4">
                                <label className="form-label">Experience with Cryptocurrency Investments (if any):</label>
                                <input type="file" onChange={(e) => { setCryptoCurrency(e.target.files[0]) }} />
                            </div>
                            <div className="mb-3 col-md-4">
                                <label className="form-label">Experience with Token Offerings (if any)</label>
                                <input type="file" onChange={(e) => { setTokenOffers(e.target.files[0]) }} />
                            </div>
                            <div className="mb-3 col-md-4">
                                <label className="form-label">Compliance and Verification: <span className='text-danger'>*</span></label>
                                <input type="file" onChange={(e) => { setVefification(e.target.files[0]) }} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className="mb-3 col-md-4">
                                <label className="form-label">Proof of Address <span className='text-danger'>*</span></label>
                                <input type="file" onChange={(e) => { setAddressProof(e.target.files[0]) }} />
                            </div>
                            <div className="mb-3 col-md-4">
                                <label className="form-label">Proof of Business Registration:</label>
                                <input type="file" onChange={(e) => { setBusinessReg(e.target.files[0]) }} />
                            </div>
                            <div className="mb-3 col-md-4">
                                <label className="form-label">Proof of Address of Business:</label>
                                <input type="file" onChange={(e) => { setAddressBusiness(e.target.files[0]) }} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className="mb-3 col-md-6">
                                <label className="form-label">Financial Statements for the Last Fiscal Year <span className='text-danger'>*</span></label>
                                <input type="file" onChange={(e) => { setFileAddressProof(e.target.files[0]) }} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label className="form-label">ny Additional Documents Supporting Financial Information::</label>
                                <input type="file" onChange={(e) => { setFileExtraProof(e.target.files[0]) }} />
                            </div>
                        </div>
                        
                        <div className="mb-3">
                            <p className='disclamr'>
                                Declaration: <br />
                                We, the undersigned, on behalf of {formData.anual_income}, hereby declare that the information provided above is accurate and complete to the best of our knowledge. We understand the risks associated with cryptocurrency investments and confirm our intention to invest in Cunningham Mining.
                            </p>
                            <hr />
                        </div>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div>
                                    <ReCAPTCHA
                                        sitekey="6LcHwQUpAAAAAG_RZeMJs-oON-mGHGp_2bplJN-O"
                                        onChange={onChange}
                                        theme="dark"
                                    />
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <button type="button" className="btn btn-primary btn-block btn-lg"
                                    onClick={handalSubmit}>Submit</button>
                            </div>
                        </div>
                        <ToastContainer
                            position="bottom-right"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="colored" />
                    </form>
                </div>
    </div>
  )
}

export default Business